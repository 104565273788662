@import "functions";

// type
$main-font: "Klee One", cursive !default;
$font-size-root:              null !default;
$font-size-base:              1rem !default;
$h1-font-size:                $font-size-base * 4.5 !default;
$h2-font-size:                $font-size-base * 2.625 !default;
$h3-font-size:                $font-size-base * 2.25 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;

$headings-font-family:        $main-font !default;
$headings-font-weight:        400 !default;

$display-font-family: $main-font !default;
$display-font-weight: 600 !default;

$lead-font-size:              $font-size-base * 1.125 !default;

// rfs
$rfs-breakpoint: 1400px !default;
$rfs-base-value: 1rem !default;

@import "variables";
@import "maps";
@import "mixins";
@import "utilities";
//@import "custom";

// Layout & components
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "containers";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
//@import "dropdown";
//@import "button-group";
@import "nav";
@import "navbar";
@import "card";
//@import "accordion";
//@import "breadcrumb";
//@import "pagination";
@import "badge";
@import "alert";
//@import "progress";
@import "list-group";
@import "close";
//@import "toasts";
@import "modal";
//@import "tooltip";
//@import "popover";
@import "carousel";
@import "spinners";
//@import "offcanvas";
@import "placeholders";

// Helpers
@import "helpers";

// Utilities
@import "utilities/api";
// scss-docs-end import-stack

body {
  //overflow-x: hidden;
  position: relative;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  @include media-breakpoint-down(xl) {
    padding-top: 60px;
  }
}

header {
  position: relative;
}

footer {
  padding-top: 80px;
  .sub & {
    margin-top: 10%;
  }
}

section {
  position: relative;
  @include media-breakpoint-up(xl) {
    p,
    li {
      font-size: $font-size-base * 1.125;
    }
  }
}

.row {
  position: relative;
}

.bg-image {
  position: relative;
  z-index: 0;
  margin-bottom: 0;
}
.contents {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
}
.navbar {
  @include media-breakpoint-down(xl) {
    height: 60px;
    padding-top: 4px;
    padding-bottom: 4px;
    //background: #000;
    --bs-navbar-toggler-focus-width: 1px;
    .container-fluid {
      height: 100%;
    }
    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      display: block;
      margin: auto;
      content: "";
      background: #000;
    }
  }
}
@include media-breakpoint-down(xl) {
  .collapse:not(.show) {
    display: block;
  }
  .navbar-collapse {
    position: fixed;
    top: 60px;
    right: 100%;
    bottom: 0;
    left: -100%;
    display: block;
    padding-right: 1em;
    padding-left: 1em;
    background: $black;
    transition-timing-function: ease;
    transition-duration: .35s;
    transition-property: right, left, transform;
    transform: translateX(-100%);
    &.show {
      right: 30%;
      left: 0;
      transform: translateX(0);
      @include media-breakpoint-up(md) {
        right: 60%;
      }
      &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        display: block;
        width: 100vw;
        margin: auto;
        content: "";
        background: rgba(0, 0, 0, .9);
      }
    }
  }
  .navbar-brand {
    position: relative;
    z-index: 10;
    width: auto;
    padding-bottom: 0;
  }
  .navbar-toggler {
    position: relative;
    left: .5rem;
    z-index: 10;
    padding-right: .5rem;
    padding-left: .5rem;
    border: 0;
  }
  .nav-btn,
  .nav-btn::before,
  .nav-btn::after {
    position: absolute;
    z-index: 10;
    display: block;
    width: 50px;
    height: 2px;
    content: "";
    background: #fff;
  }

  .nav-btn {
    top: 50%;
  }
  .nav-btn::before {
    top: 0;
  }

  .nav-btn::after {
    bottom: 0;
  }
  .nav-link {
    display: inline-block;
  }
}

.nav-item {
  @include media-breakpoint-up(xl) {
    margin-left: 32px;
  }
  @include media-breakpoint-down(sm) {
    margin-right: 16px;
  }
  @include media-breakpoint-between(sm,md) {
    margin-right: 32px;
  }
  @include media-breakpoint-between(md,xl) {
    margin-right: 48px;
  }
}
.nav-link {
  font-family: "Klee One", cursive;
  font-size: 1.2rem;
  color: #000;
  @include media-breakpoint-down(xl) {
    padding-right: .75em;
    padding-left: .75em;
  }
  &.line {
    @include media-breakpoint-up(xl) {
      background: url("../images/line01.svg") no-repeat bottom center/ 100% 5px;
    }
    @include media-breakpoint-down(xl) {
      background: url("../images/line01wh.svg") no-repeat bottom center/ 100% 5px;
    }
  }
}

/* form */

.bg-black {
  .nav-link {
    color: var(--bs-white);
  }
}

.btn.btn-gradient-orange {
  position: relative;
  width: 330px;
  padding-top: 15px;
  padding-bottom: 15px;
  overflow: hidden;
  //font-size: 1.5rem;
  background-image: linear-gradient(90deg, #ff9600 34%, #ff5400 100%);
  &:link,
  &:visited,
  &:hover,
  &:active,
  &:focus {
    color: var(--bs-white);
  }
  &:hover {
    &::after {
      position: absolute;
      top: -100px;
      left: -100px;
      width: 50px;
      height: 50px;
      overflow: hidden;
      content: "";
      background-image: linear-gradient(100deg, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, .6) 100%, rgba(255, 255, 255, 0) 0%);

      /* アニメーション */
      animation-name: shining;
      animation-duration: 2s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
    }
  }
}

@keyframes shining {
  0% {
    opacity: 1;
    transform: scale(1) rotate(25deg);
  }

  100% {
    opacity: 0;
    transform: scale(50) rotate(25deg);
  }
}

/* page */
#h-social {
  align-items: center;
  margin-bottom: 0;
  margin-left: 1rem;
  a {
    color: #000;
  }
  @include media-breakpoint-between(xl,xxl) {
    position: absolute;
    top: 100%;
    right: 1rem;
  }
  @include media-breakpoint-down(xl) {
    position: absolute;
    top: 50%;
    right: 48px;
    z-index: 10;
    transform: translateY(-50%);
    a {
      color: #fff;
    }
  }
}
.main-visual {
  header & {
    @include media-breakpoint-down(xl) {
      height: 100%;
    }
    @include media-breakpoint-between(md,lg) {
      @media (orientation: portrait) {
        padding-top: 10vmin;
      }
    }
    @include media-breakpoint-down(lg) {
      @media (orientation: portrait) {
        padding-top: 10vmin;
      }
      // background: rgba(0, 255, 0, .25);
    }
    @include media-breakpoint-between(sm, lg) {
      height: 100%;
      padding-top: 0;
      // background: rgba(255, 0, 0, .25);
    }
  }
  .lead {
    font-family: $main-font;
  }
}

.page-title-container {
  @media (orientation: landscape) {
    align-items: center;
  }
  @include media-breakpoint-down(lg) {
    justify-content: center;
  }
  @include media-breakpoint-between(sm,xl) {
    @media (orientation: landscape) {
      justify-content: start;
      #omamori-nft &,
      #nft-buddha &,
      #metaverse-temple & {
        margin-left: 1.5rem;
      }
    }
    @media (orientation: portrait) {
      padding-top: 10vmin;
    }
  }
  .sub & {
    align-items: center;
    justify-content: center;
    padding-top: 0;
  }
}

.sub {
  .main-visual {
    padding-top: 0;
  }
  .lead {
    padding-left: 0;
  }
  .sec-title {
    margin-bottom: 72px;

    b {
      padding: 0 1em 8px;
      background: url("../images/line01.svg") no-repeat bottom center/100% 10px;
    }
  }
}

.page-title-en,
.main-font {
  font-family: $main-font;
}
.page-title {
  margin-bottom: .25em;
  b {
    display: inline-block;
    padding-right: .5em;
    padding-bottom: .5em;
    padding-left: .5em;
    font-weight: 600;
    background: url("../images/line02.svg") no-repeat bottom center/ 100% .5em;
    @include media-breakpoint-up(xxl) {
      padding-right: 1em;
      padding-left: 1em;
      background-size: 100% 32px;
    }
  }
}
.page-title-en {
  font-weight: 400;
}

.sec-btn {
  margin-top: 5%;
  @include media-breakpoint-up(xl){
    margin-top: 60px;
  }
}

.scroll {
  position: relative;
  z-index: 2;
  display: block;
  width: 25vmin;
  max-width: 112px;
  margin: auto;
  content: "";
  @include media-breakpoint-up(xxl) {
    width: 112px;
  }
  img {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    margin: auto;
    transform: translateY(-50%);
  }
  + * {
    z-index: 1;
  }
}

.line03 {
  opacity: .25;
}

.page-tab {
  padding-top: 152px;
  @include media-breakpoint-down(md) {
    padding-top: 25vmin;
  }
  .col-md-auto {
    margin: 0;
    @include media-breakpoint-up(md) {
      height: 3.5rem;
      border-right: 5px #aeaeae solid;
      &:first-child {
        border-left: 5px #aeaeae solid;
      }
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: .5rem;
    }
  }
  .btn-link {
    padding: 0 25px;
    font-family: $main-font;
    font-weight: 600;
    color: $black;
    text-decoration: none;
    @include media-breakpoint-down(sm) {
      display: flex;
      align-items: center;
      width: 100%;
      padding: .5rem;
      color: $white;
      background: $black;
      .fa-stack {
        position: absolute;
        flex-shrink: 0;
      }
      span:not([class]) {
        display: block;
        flex-grow: 1;
        text-align: center;
      }
    }
  }
}

.outline {
  padding-top: 2rem;
  padding-bottom: 15vmin;
  @include media-breakpoint-up(md) {
    padding-bottom: 10vmin;
  }
  @include media-breakpoint-up(xxl) {
    padding-top: 70px;
    padding-bottom: 112px;
  }
  .sec-title {
    margin-bottom: 2.25rem;
    @include media-breakpoint-down(xxl) {
      margin-bottom: rfs-fluid-value(2.25rem);
    }
  }
}

/* ==============================
 * ホーム
 * ============================== */
#index {
  [class*="index"] {
    .row.h-100 {
      @media (orientation: landscape) {
        justify-content: start;
        @include media-breakpoint-between(md, lg) {
          padding-top: 5vmin;
          padding-bottom: 5vmin;
        }
      }
      @media (orientation: portrait) {
        justify-content: center;
      }
    }
    .col-10 {
      @include media-breakpoint-between(sm,lg) {
        @media (orientation: landscape) {
          width: 60%;
          margin-left: 1%;
        }
      }
    }
  }

  .sec-title {
    line-height: 1;
  }

  .btn.btn-gradient-orange {
    min-width: 330px;
    padding-top: 15px;
    padding-bottom: 15px;
    line-height: 1;
  }

  #omamori-nft {
    .sec-title {
      margin-bottom: 22px;
    }
  }
  #nft-buddha {
    color: $white;
    .sec-title {
      margin-bottom: 12px;
    }
  }
  #metaverse-temple {
    background: none;
    .sec-title {
      margin-bottom: 25px;
    }
  }
}

// 縦向き
@media (orientation: portrait) {
  #splash-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    //z-index: 1031;
    width: 100%;
    overflow: hidden;
    background: linear-gradient(180deg, #fff calc(60px + 96.66vw), #000 96.66vw calc(96.66vw * 2 + 60px), #fff calc(96.66vw * 2 + 60px));
  }
  #splash {
    position: relative;
    display: block;
    height: 100%;
    overflow: hidden;
    .page-title {
      width: 55vmin;
    }
    figure {
      position: absolute;
      top: 10vmin;
      left: 10vmin;
      width: 110vmin;
      overflow: hidden;
    }
  }
  #character {
    position: relative;
    z-index: -1;
    display: none;
  }
}

// 横向き
@media (orientation: landscape) {
  #splash-container {
    display: none;
  }
  #character {
    position: absolute;
    top: 30px;
    left: 50%;
    z-index: 10;
    display: block;
    max-width: 48%;
    @media (orientation: portrait) {
      display: none;
    }
    @include media-breakpoint-down(lg) {
      right: 0;
      left: initial;
      width: 47%;

    }
  }
}

/* ==============================
 * 御守NFT
 * ============================== */
#omamori-nft {
  header {
    background-color: #fff;
    background-image: linear-gradient(90deg, #f2f2f0 0%, #ededec 25%, #f0f0ee 50%, rgba(#f4f5f3, .9) 75%, rgba(#f7f7f7, .25) 100%);
  }
}
#variations {
  padding-bottom: 70px;
  .sample {
    margin-bottom: $spacer * 5;
    @include media-breakpoint-up(xxl) {
      margin-bottom: 150px;
    }
    [class*="col"] {
      padding-top: 15vmin;
      padding-bottom: 3rem;
      @include media-breakpoint-up(xxl) {
        padding-top: 88px;
        padding-bottom: 55px;
      }
    }
    h3 {
      margin-bottom: 2rem;
      @include media-breakpoint-up(xxl) {
        margin-bottom: 56px;
      }
    }
  }
}

#inside {
  .sec-title {
    margin-bottom: .9em;
    text-align: center;
  }
  .lead {
    margin-bottom: 3.6%;
  }
  @include media-breakpoint-up(xl){
    .lead {
      margin-bottom: 70px;
    }
  }
  @include media-breakpoint-up(lg) {
    .sec-title {
      margin-bottom: 38px;
      text-align: left;
    }
  }
  @include media-breakpoint-down(lg) {
    .contents {
      padding-top: 10%;
      padding-bottom: 10%;
      @media (orientation: portrait) {
        .row.h-100 {
          justify-content: center;
        }
      }
    }
  }
  @include media-breakpoint-down(sm){
    .sec-title {
      letter-spacing: -1px;
    }
  }
  @include media-breakpoint-down(sm) {
    .sec-btn {
      position: absolute;
      right: 0;
      bottom: 2.625rem;
      left: 0;
      justify-content: center;
      margin: auto;
    }
  }
  @include media-breakpoint-between(sm,lg) {
    @media (orientation: landscape) {
      .contents {
        align-items: center;
        padding-top: initial;
        padding-bottom: initial;
      }
      .row.h-100 {
        align-items: center;
      }
      .sec-title {
        text-align: left;
      }
      .lead {
        text-shadow: 0 0 .25em #fff, 0 0 .25em #fff, 0 0 .25em #fff, 0 0 .25em #fff;
      }
    }
  }
}

#slider {
  @include media-breakpoint-up(lg) {
    max-width: 74%;
    margin-right: auto;
    margin-left: auto;
  }
}

#lineup {
  padding-top: 110px;
  @include media-breakpoint-down(lg) {
    padding-top: 15vmin;
  }
  .sec-title {
    margin-bottom: 70px;
  }
  .buy-btn {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 330px;
  }
}

#fortune {
  padding-top: 88px;
  .sec-title {
    margin-bottom: 38px;
  }
  .lead {
    margin-bottom: 70px;
  }
  .btn {
    margin-top: 60px;
  }
}

/* ==============================
 * NFT-Buddha
 * ============================== */
$special03: 480 !default;

$special01: $special03 * .475 !default;
$special02: $special03 * .6875 !default;


#collaboration {
  padding-top: 90px;
  padding-bottom: 72px;
  @include media-breakpoint-up(xxl) {
    padding-top: 177px;
    padding-bottom: 162px;
  }
  p:first-of-type {
    margin-bottom: 87px;
  }
  .sample {
    max-width: 1250px;
    margin-bottom: 110px;
  }
}

#special {
  position: relative;
  padding-top: 137px;
  overflow: hidden;
  .sec-title {
    margin-bottom: 59px;
  }
  p {
    margin-bottom: 89px;
  }
}

[class*="special"] {
  position: relative;
  z-index: 1;
  max-width: 100%;
  margin-bottom: 9.3vmax;
  text-align: center;
  @include media-breakpoint-down(lg) {
    margin-bottom: 20vmin;
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 28vmin;
  }
}


.nft-image {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  --animate-delay: 2s;
  .special01 & {
    max-width: $special01 + px;
  }
  .special02 & {
    max-width: $special02 + px;
  }
  .special03 & {
    max-width: $special03 + px;
  }
  @include media-breakpoint-down(xl) {
    @media (orientation: portrait) {
      .special01 & {
        width: calc($special01 / $special03 * 100vw);
      }
      .special02 & {
        width: calc($special02 / $special03 * 100vw);
      }
      .special03 & {
        width: 80vw;
      }
    }
    @media (orientation: landscape) {
      .special01 & {
        width: calc($special01 / 1920 * 100%);
      }
      .special02 & {
        width: calc($special02 / 1920 * 100%);
      }
      .special03 & {
        width: calc($special03 / 1920 * 100%);
      }
    }
  }
}

.dom {
  position: absolute;
  //right: 80%;
  z-index: 2;
  display: inline-block;
  //max-width: 100%;
  .special01 & {
    top: calc((-4 / $special01) * 100%);
    right: calc((180 / $special01) * 100%);
    width: calc((204 / $special01) * 100%);
  }
  .special02 & {
    top: calc((5 / $special02) * 100%);
    right: calc((285 / $special02) * 100%);
    width: calc((297 / $special02) * 100%);
  }
  .special03 & {
    top: calc((26 / $special03) * 100%);
    right: calc((347 / $special03) * 100%);
    width: calc((555 / $special03) * 100%);
  }
  img {
    max-width: 100%;
    height: auto;
  }
  @media (orientation: portrait) {
    [class*="special0"] & {
      top: -35%;
    }
    @include media-breakpoint-down(md)  {
      .special01 & {
        left: -20%;
      }
      .special02 & {
        left: -15%;
      }
      .special03 & {
        left: -10%;
      }
    }
    @include media-breakpoint-between(md,lg)  {
      .special01 & {
        left: -50%;
      }
      .special02 & {
        left: -40%;
      }
      .special03 & {
        left: -30%;
      }
    }
  }
}

#particle {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  display: block;
  width: 100%;
  //padding-top: calc((1080 / 1920) * 100%);
  margin: auto;
  @include media-breakpoint-up(lg) {
    width: 100%;
  }
  @include media-breakpoint-down(lg) {
    width: calc(16 / 9 * 100%);
    @media (orientation: portrait) {
      bottom: 10%;
    }
  }
  &::before {
    position: absolute;
    top: -1px;
    right: 0;
    bottom: -1px;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    margin: auto;
    content: "";
    background: linear-gradient(rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, .9) 25%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, .9) 75%, rgba(0, 0, 0, 1) 100%);
  }
  #particle-content {
    width: 100%;
    height: auto;
  }
}


/* ==============================
 * メタバース寺院
 * ============================== */
#metaverse-temple {
  header {
    background-color: #c5ecfd;
    background-image: linear-gradient(0deg, #c5ecfd 0%, #c5ecfd 25%, #c5ecfd 50%, #c5ecfd 75%, #c5ecfd 100%);
  }
}
#concept-movie {
  padding-top: 90px;
  @include media-breakpoint-up(xl) {
    padding-top: 252px;
  }
}

#temple-outline {
  padding-top: 90px;
  @include media-breakpoint-up(xl) {
    padding-top: 180px;
  }
  [class*="lineup"] {
    position: relative;
    max-width: 1075px;
    min-height: 313px;
    padding-top: 45px;
    padding-right: 72px;
    padding-left: 56px;
    margin-right: auto;
    margin-bottom: 88px;
    margin-left: auto;
    color: $white;
    background: url("../images/metaverse-temple/bg01@2x.png") no-repeat top center/ 100% 100%;
    .title {
      margin-bottom: 34px;
      margin-left: -.25em;
      span {
        display: inline-block;
        padding-right: .75em;
        padding-left: .75em;
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: contain;
      }
    }
    @include media-breakpoint-between(sm, lg) {
      max-width: 100%;
      padding-top: 2rem;
      padding-right: 2.25rem;
      padding-bottom: 1rem;
      padding-left: 1rem;
      margin-bottom: 3rem;
    }
    @include media-breakpoint-down(sm) {
      max-width: 100%;
      padding-top: 1rem;
      padding-right: 1rem;
      padding-bottom: 1rem;
      padding-left: 1rem;
      margin-bottom: 3rem;
      background: url("../images/metaverse-temple/bg_sp.png") no-repeat bottom center/ 100% 100%;
    }
    .image {
      margin-bottom: 0;
      @include media-breakpoint-down(lg) {
        display: block;
        width: 36%;
        margin-right: auto;
        margin-left: auto;
      }
      @include media-breakpoint-up(lg) {
        position: absolute;
        margin-left: auto;

      }
    }
  }
  .lineup01 {
    .title {
      span {
        padding-bottom: 17px;
        background-image: url("../images/line11wh.svg");
        background-size: 100% 17px;
      }
    }
    .image {
      @include media-breakpoint-up(lg) {
        right: 60px;
        bottom: 35px;
      }
    }
  }
  .lineup02 {
    .title {
      span {
        padding-bottom: 11px;
        background-image: url("../images/line05wh.svg");
        background-size: 100% 11px;
      }
    }
    .image {
      top: 31px;
      right: 98px;
      @include media-breakpoint-down(lg) {
        top: initial;
        right: 0;
        bottom: 2rem;
        left: 0;
        margin: auto;
      }
    }
  }
  .lineup03 {
    .title {
      span {
        padding-right: 1em;
        padding-bottom: 15px;
        background-image: url("../images/line01wh.svg");
        background-position: bottom right;
        background-size: 100% 15px;
      }
    }
    .image {
      right: 98px;
      bottom: 23px;
      @include media-breakpoint-down(lg) {
        right: 0;
        bottom: .5rem;
        left: 0;
        width: 30%;
        margin: auto;
      }
    }
  }
  .lineup04 {
    .title {
      span {
        padding-bottom: 15px;
        background-image: url("../images/line04wh.svg");
        background-size: 100% 15px;
      }
    }
    .image {
      right: 80px;
      bottom: 33px;
      @include media-breakpoint-down(lg) {
        right: 1rem;
        bottom: .5rem;
      }
    }
  }
  .lineup05 {
    .title {
      span {
        padding-bottom: 11px;
        background-image: url("../images/line07wh.svg");
        background-size: 100% 11px;
      }
    }
    .image {
      right: 78px;
      bottom: 45px;
      @include media-breakpoint-down(lg) {
        right: 0;
        bottom: .5rem;
        left: 0;
        width: 42%;
        margin: auto;
      }
    }
  }
  .lineup06 {
    .title {
      span {
        padding-bottom: 17px;
        background-image: url("../images/line10wh.svg");
        background-size: 100% 17px;
      }
    }
    .image {
      top: 22px;
      right: 83px;
      @include media-breakpoint-down(lg) {
        top: initial;
        right: 1rem;
        bottom: .5rem;
        width: 45%;
      }
    }
  }
}


/* ==============================
 * プライバシーポリシー
 * ============================== */
#privacy {
  .container-xxl {
    max-width: 1140px;
  }
}
#policies,
#terms,
#environment,
#virtual,
#cash {
  padding-top: 224px;
  @include media-breakpoint-down(md) {
    padding-top: 25vmin;
  }
  dt {
    font-family: $main-font;
    font-weight: 600;
    @include media-breakpoint-up(lg) {
      margin-bottom: 50px;
    }
  }
  dd {
    @include media-breakpoint-up(lg) {
      margin-bottom: 140px;
    }
  }
}

/* ==============================
 * ご利用規約
 * ============================== */
#terms,
#environment {
  padding-top: 100px;
  padding-bottom: 100px;
}

/* ==============================
 * NFT購入の流れ
 * ============================== */
#virtual,
#cash {
  padding-top: 100px;
  padding-bottom: 100px;
}

/* ==============================
 * お問い合わせ
 * ============================== */
#contact {
  .sec-title{
    margin-bottom: 1em;
    b {
      background: none;
    }
  }
  #details {
    padding-top: 140px;
    padding-bottom: 100px;
    .container-xxl {
      max-width: 960px;
    }
  }
}

/* ==============================
 * ロードマップ
 * ============================== */
#path {
  padding-top: 25vmin;
  padding-bottom: 25vmin;
  font-weight: 700;
  @include media-breakpoint-up(lg) {
    padding-top: 225px;
    padding-bottom: 225px;
  }
  @include media-breakpoint-up(md) {
    padding-top: 115px;
    padding-bottom: 115px;
  }
  dt,
  dd {
    font-family: $main-font;
  }
  dd {
    @extend .mb-5;
    &:last-child {
      @extend .mb-0;
    }
  }
  p {
    font-family: $main-font;
  }
}


#gokurakuji {
  #contents {
    padding-top: 150px;
    .row {
      margin-bottom: 150px;
    }
    .sec-title {
      margin-bottom: 50px;
    }
  }
}
